/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { find } from 'lodash';
import { Country } from '../../../types/Country';
import { useCountry } from '../../../containers/CountryContainer';
import { DebugFooterWrapper } from '../../../pagestyles/components/common/DebugFooterWrapper';
import { useHeader } from '../../../containers/HeaderContainer';

type Props = {
  countries: Country[];
};
const DebugFooter: React.FC<Props> = (props: Props) => {
  const { countries } = props;
  const env = process.env.NEXT_PUBLIC_ENV;
  const api = process.env.NEXT_PUBLIC_API;
  const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
  const vercel = vercelEnv !== undefined;
  const country = useCountry((container) => container.country);
  const setCountry = useCountry((container) => container.setCountry);
  const detectedCountry = useCountry((container) => container.detectedCountry);
  const debug = useHeader((container) => container.debug);

  const onChangeCountry = (code: string) => {
    const c = find(countries, (cn) => cn.country === code);
    if (!c) return;
    setCountry(c);
  };

  return (
    <DebugFooterWrapper>
      {debug && (
        <div className='container'>
          <div className='debug'>
            <p>
              Environment {env} connected to {api}
            </p>
            <p>GTM property {process.env.NEXT_PUBLIC_GTM_ID}</p>
            <p>Intercom Id {process.env.NEXT_PUBLIC_INTERCOM_ID}</p>
            <p>Sentry DSN {process.env.NEXT_PUBLIC_SENTRY_DSN}</p>
            <p>Stripe key: {process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}</p>
            <p>Braintree key: {process.env.NEXT_PUBLIC_TOKENIZATION_KEYS}</p>
            <p>Launchdarkly key: {process.env.NEXT_PUBLIC_LAUNCH_CLIENT_SIDE_ID}</p>
            <p>Detected country: {detectedCountry?.country}</p>
            <p>
              Force country:
              <select value={country?.country} onChange={(e) => onChangeCountry(e.target.value)}>
                {countries.map((c) => (
                  <option key={c.country} value={c.country}>
                    {c.countryLabel}
                  </option>
                ))}
              </select>
            </p>
            <p>Country: {country?.country}</p>
            <p>Google ReCaptcha key: {process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}</p>
            <p>Google ReCaptcha key v3: {process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY}</p>
            <p>Cherry endpoint: {process.env.NEXT_PUBLIC_FOUR2_ENDPOINT}</p>
            <p>Cherry api key: {process.env.NEXT_PUBLIC_FOUR2_API_KEY}</p>
            <p>Cherry tenant: {process.env.NEXT_PUBLIC_FOUR2_TENANT}</p>
            <p>Cherry checkout promo recommender id: {process.env.NEXT_PUBLIC_FOUR2_CHECKOUT_PROMO_RECOMMENDER}</p>
            <p>Marry endpoint: {process.env.NEXT_PUBLIC_MARRY_ENDPOINT}</p>
            <p>Marry api key: {process.env.NEXT_PUBLIC_MARRY_API_KEY}</p>
            <p>Rokt-launcher account id: {process.env.NEXT_PUBLIC_LAUNCHER_ACCOUNT_ID}</p>
            {vercel && (
              <>
                <p>
                  Vercel env: {process.env.NEXT_PUBLIC_VERCEL_ENV}
                  <br />
                  Build: {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF}
                  <br />
                  Hash: {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
                  <br />
                  Commit: {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE}
                  <br />
                  Author: {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </DebugFooterWrapper>
  );
};

export default DebugFooter;
