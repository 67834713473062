/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { useRouter } from 'next/router';
import { Country } from '../../types/Country';
import { useHeader } from '../../containers/HeaderContainer';
import { Setting } from '../../types/Setting';
import NewFooter from '../new-homepage/NewFooter';
import DebugFooter from '../common/debug/DebugFooter';

type Props = {
  countries: Country[];
  setting: Setting;
};
const Footer: React.FC<Props> = (props: Props) => {
  const showHeader = useHeader((container) => container.showHeader);
  const { countries, setting } = props;
  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);
  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    const noVertical = 992;
    if (window.innerWidth < noVertical) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, []);

  const displayFooter = (): boolean => {
    let display = true;
    const notDisplayUrls = [
      '/auth/login',
      '/auth/forgot-password',
      '/auth/reset-password',
      '/survey',
      '/404',
      '/500',
      'checkout/review',
      'checkout/place-order',
      'checkout/one-off',
      'label',
      '/maintenance',
      '/checkout/confirmation',
      '/checkout/one-off/confirmation',
      '/recommendation',
      '/quick-quiz',
      '/mobile-app',
      '/unsubscribe',
      'widgets',
    ];
    const notAllow = find(notDisplayUrls, (path) => asPath.includes(path));
    if (asPath.search('auth/reset-password') > -1) display = false;
    const disableMobileUrls = ['/mobile-app'];
    const mobileDisable = find(disableMobileUrls, (path) => asPath.includes(path));
    if (notAllow || !showHeader) display = false;
    if (mobileDisable && isMobileScreen) display = false;
    return display;
  };

  return (
    <>
      {displayFooter() && (
        <>
          <NewFooter setting={setting} />
          <DebugFooter countries={countries} />
        </>
      )}
    </>
  );
};
export default Footer;
