import React, { useState } from 'react';
import Link from 'next/link';
import { useFetch } from 'use-http';
import { Modal } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { ModalBody, ModalHeader, NewFooterWrapper } from '../../pagestyles/components/new-homepage/NewFooterWrapper';
import { BECOME_AFFILIATES_LINK, DOWNLOAD_APP_LINK, DOWNLOAD_APP_STORE, DOWNLOAD_GG_PLAY, FACEBOOK_LINK, FAQ_LINK, INSTAGRAM_LINK, RESEARCH_LINK, SHIPPING_DELIVERY_LINK } from '../../common/const';
import Collapsible from '../Collapsible';
import { Setting } from '../../types/Setting';
import { useHeader } from '../../containers/HeaderContainer';

type Props = {
  setting: Setting,
};

const NewFooter: React.FC<Props> = ({ setting }: Props) => {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const setDebug = useHeader((container) => container.setDebug);
  const currentYear = new Date().getFullYear();
  const { post, response } = useFetch('/api/v1/invitations');
  const { show } = useIntercom();

  const addClick = () => {
    setClickCount(() => clickCount + 1);
    if (clickCount >= 5) setDebug(true);
  };

  const onSubmitNewsletter: React.FormEventHandler<HTMLFormElement> = async (
    e,
  ) => {
    e.preventDefault();
    const data = {
      invitations: email,
    };
    await post(data);
    if (response.status === 200) {
      setEmail('');
      setShowModal(true);
    }
  };

  return (
    <NewFooterWrapper>
      <div className="footer">
        <div className='logo'>
          <Link href="/">
            <a href="/">
              Vitable
            </a>
          </Link>
        </div>
        <form className='form' onSubmit={onSubmitNewsletter}>
          <p>Subscribe to our newsletter for monthly tips on feelin<span>g good&nbsp;&nbsp;&nbsp;</span></p>
          <div className="input-form">
            <input
              value={email}
              name="email"
              type="email"
              className="form-control input-email"
              placeholder="Your e-mail"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className="btn button-submit" type="submit">
              &nbsp;
            </button>
          </div>
        </form>
        <div className="social">
          <Link href={INSTAGRAM_LINK}>
            <a className="instagram" href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
              Instagram
            </a>
          </Link>
          <Link href={FACEBOOK_LINK}>
            <a className="facebook" href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
              Facebook
            </a>
          </Link>
        </div>
        <div className='details'>
          <Collapsible className='item item-1' label="Information">
            <ul>
              <li>
                <Link href="/products">
                  <a className="link-products" href="/products">
                    Our products
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/about">
                  <a className="link-about" href="/about">
                    About us
                  </a>
                </Link>
              </li>
              <li>
                <Link href={RESEARCH_LINK}>
                  <a target="_blank" rel="noreferrer" href={RESEARCH_LINK}>
                    Research
                  </a>
                </Link>
              </li>
              
            </ul>
          </Collapsible>
          <Collapsible className='item item-2' label="Resource">
            <ul>
              {setting.giftCardEnabled && (
                <li>
                  <Link href="/gift-card">
                    <a href="/gift-card">Gift card</a>
                  </Link>
                </li>
              )}
              <li className='desktop'>
                <Link href={DOWNLOAD_APP_STORE}>
                  <a target="_blank" href={DOWNLOAD_APP_STORE} rel="noreferrer" >
                    Download the App on App Store
                  </a>
                </Link>
              </li>
              <li className='desktop'>
                <Link href={DOWNLOAD_GG_PLAY}>
                  <a target="_blank" href={DOWNLOAD_GG_PLAY} rel="noreferrer" >
                    Download the App on Google Play
                  </a>
                </Link>
              </li>
              <li className='mobile'>
                <Link href={DOWNLOAD_APP_LINK}>
                  <a target="_blank" href={DOWNLOAD_APP_LINK} rel="noreferrer" >
                    Download the Vitable app 
                  </a>
                </Link>
              </li>
              <li>
                <Link href={BECOME_AFFILIATES_LINK}>
                  <a target="_blank" rel="noreferrer" href={BECOME_AFFILIATES_LINK}>
                    Become an affiliate
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/blog">
                  <a href="/blog">Blog</a>
                </Link>
              </li>
            </ul>
          </Collapsible>
          <Collapsible className='item item-3' label="Support">
            <ul>
              <li>
                <Link href={SHIPPING_DELIVERY_LINK}>
                  <a target="_blank" rel="noreferrer" href={SHIPPING_DELIVERY_LINK}>
                    Shipping & delivery
                  </a>
                </Link>
              </li>
              <li>
                <Link href={FAQ_LINK}>
                  <a target="_blank" rel="noreferrer" href={FAQ_LINK}>
                    FAQ
                  </a>
                </Link>
              </li>
              <li>
                <button type="button" className="care-btn" onClick={show}>Chat with us</button>
              </li>
            </ul>
          </Collapsible>
          <Collapsible className='item item-4' label="Policies">
            <ul>
              <li>
                <Link href="/privacy-policy">
                  <a href="/privacy-policy">
                    Privacy policy
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/terms-condition">
                  <a href="/terms-condition">
                    Terms & conditions
                  </a>
                </Link>
              </li>
            </ul>
          </Collapsible>
        </div>
        <div className='copyright'>
          <p className='current-year' onClick={addClick} aria-hidden>
            © {currentYear} Vitable. All rights reserved, 
          </p>
          <p className="disclaimer">
            Always read the label. Follow the directions for use. If
            symptoms persist, change or worsen talk to your health
            professional. Vitamin supplements should not replace a
            balanced diet.
          </p>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <ModalHeader closeButton />
        <ModalBody>Thanks for signing up.</ModalBody>
      </Modal>
    </NewFooterWrapper>
  )
};
export default NewFooter;
