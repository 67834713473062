/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CollapsibleWrapper = styled.div`
  .content-parent {
    height: 0px;
    overflow: hidden;
    transition: height ease 0.9s;
  }
    
  .content-show {
    height: 200px;
  }
`;
