/* eslint-disable no-param-reassign */
import React, { ReactNode, useRef } from 'react';
import { CollapsibleWrapper } from '../pagestyles/components/CollapsibleWrapper';

interface Props {
  label: string;
  children: ReactNode,
  className?: string,
}

const Collapsible: React.FC<Props> = ({
  label, children, className
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contentRef = useRef<any>();
  const toggle = () => {
    // console.log('open', open);
    const elements = Array.from(document.getElementsByClassName('content-parent') as HTMLCollectionOf<HTMLElement>);
    if (elements && elements.length) {
      elements.forEach((item: HTMLElement) => {
        if (item.ariaLabel !== label) {
          item.style.height = '0px';
          const button = document.querySelector(`[aria-label='btn-${item.ariaLabel}']`);
          if (button) {
            button.classList.add('open');
            button.classList.remove('close');
          }
        } else {
          item.style.height = item.offsetHeight === 0 ? `${contentRef.current.scrollHeight}px` : '0px';
          const button = document.querySelector(`[aria-label='btn-${label}']`);
          if (button) {
            if (item.offsetHeight === 0) {
              button.classList.add('close');
              button.classList.remove('open');
            } else {
              button.classList.add('open');
              button.classList.remove('close');
            }
          }
        }
        return item;
      });
    }
  };
  return (
    <CollapsibleWrapper className={className}>
      <button type='button' onClick={toggle} aria-label={`btn-${label}`}>{label}</button>
      {/* <div className={open ? "content-show" : "content-parent"}>
        <div className='content'> {children} </div>
      </div> */}
      <div
        className="content-parent"
        ref={contentRef}
        // style={open ? { height: `${contentRef.current.scrollHeight}px` } : { height: "0px" }}
        aria-label={label}
      >
        <div className='content'> {children} </div>
      </div>
    </CollapsibleWrapper>
  );
};
Collapsible.defaultProps = {
  className: '',
};
export default Collapsible;
