/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { black, grotesk, red, solaire, white } from '../../../../styles/theme';

export const NewFooterWrapper = styled.section`
  @media screen and (max-width: 1024px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @media screen and (min-width: 1024px) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
  background: #FAF9F6;
  .logo {
    grid-area: logo;
  }
  .form {
    grid-area: form;
  }
  .social {
    grid-area: social;
  }
  .details {
    grid-area: details;
  }
  .copyright {
    grid-area: copyright;
  }
  .footer {
    display: grid;
    grid-template-areas:
      'logo form form'
      'social details details'
      'copyright copyright copyright';
    padding: 109px 101px 15px;
  }
  .logo {
    margin-bottom: 182px;
    a {
      background-image: url("/assets/images/homepage/BrandMark-Sage.png");
      width: 56px;
      height: 46px;
      background-repeat: no-repeat;
      display: inline-block;
      overflow: hidden;
      text-indent: -100em;
      margin-bottom: 20px;
    }
  }
  .form {
    p {
      font-size: 16px;
      font-family: ${solaire};
      font-style: normal;
      font-weight: 400;
      line-height: 17.6px;
      margin-bottom: 45px;
    }
    .input-form {
      position: relative;
      max-width: 480px;
      input {
        height: 36px;
        border-radius: 100px;
        border: 1px solid #DCD2BD;
        background: ${white};
        padding: 8px 0px 8px 23px;
        color: ${black};
        font-size: 14px;
        font-family: ${grotesk};
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.28px;
  
        &:focus {
          box-shadow: none;
        }
        ::placeholder {
          color: #DCD2BD;
        }
      }
      .button-submit {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 101px;
        width: 101px;
        height: 36px;
        border-radius: 100px;
        background-color: ${black};
        background-image: url("/assets/images/homepage/button-subscribe.png");
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
  }
  .social {
    margin-bottom: 316px;
    a {
      width: 100%;
      height: 24px;
      background-repeat: no-repeat;
      background-position: 15px;
      display: block;
      overflow: hidden;
      text-indent: -100em;
      margin-bottom: 32px;
      &.facebook {
        background-image: url("/assets/images/homepage/brandico_facebook.png");
        background-size: 12px 24px;
        background-position: 19px;
      }
      &.instagram {
        background-image: url("/assets/images/homepage/akar-icons_instagram-fill.png");
        background-size: 24px;
      }
    }
  }
  .details {
    display: flex;
    justify-content: space-between;
    .item {
      button {
        border: none;
        background-color: transparent;
        color: #BBB3A4;
        font-size: 14px;
        font-family: ${grotesk};
        font-style: normal;
        font-weight: 450;
        line-height: 19.6px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        padding: 0;
        margin-bottom: 56px;
      }
    }
    .content-parent {
      height: unset;
      overflow: unset;
      ul {
        li {
          margin-bottom: 26px;
        }
        li:last-child {
          margin-bottom: 0;
        }
      }
      a, button {
        color: #000;
        font-size: 16px;
        font-family: ${grotesk};
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.32px;
        text-transform: unset;
        padding: 0;
        background-image: none !important;
      }
    }
  }
  .copyright {
    display: flex;
    padding-top: 21px;
    border-top: 1px solid #DCD2BD;
    justify-content: space-between;
    p {
      color: #9D9D9D;
      font-size: 12px;
      font-family: ${grotesk};
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.6px;
      max-width: 356px;
    }
    p.disclaimer {
      position: relative;
      &:before {
        content: '*';
        position: absolute;
        top: 2px;
        left: -12px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .footer {
      padding: 100px 25px 81px;
    }
    .logo {
      margin-bottom: 150px;
    }
    .social {
      margin-bottom: 290px;
    }
    .details {
      .item {
        button {
          margin-bottom: 40px;
        }
      }
      .content-parent {
        a, button {
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 769px) {
    .footer {
      padding: 100px 25px 81px;
      grid-template-areas:
        'form form'
        'details details'
        'copyright copyright'
        'logo social';
    }
    .form {
      p {
        text-align: center;
        max-width: 282px;
        margin: auto;
        margin-bottom: 38px;
        span {
          background-image: url("/assets/images/homepage/Line-3.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 0 100%;
          padding-bottom: 5px;
        }        
      }
      .input-form {
        max-width: 305px;
        margin: auto;
        margin-bottom: 104px;
      }
    }
    .details {
      display: block;
      margin-bottom: 65px;
      .item {
        padding: 21px 0;
        border-bottom: 1px solid #F2EDE0;
        button {
          margin-bottom: 0px;
          background-repeat: no-repeat;
          background-image: url("/assets/images/homepage/ant-design_plus-outlined.png");
          background-position: 100% 0;
          width: 100%;
          text-align: left;
        }
        button.open {
          background-image: url("/assets/images/homepage/ant-design_plus-outlined.png");
        }
        button.close {
          background-image: url("/assets/images/homepage/ant-design_minus-outlined.svg");
        }
      }
      .item:first-child {
        padding-top: 0px;
      }
      .item:last-child {
        border-bottom: unset;

        .content-parent {
          ul {
            padding-bottom: 21px;
            border-bottom: 1px solid #F2EDE0;
          }
        }
      }
      .content-parent {
        height: 0;
        overflow: hidden;
        ul {
          margin-top: 21px;
          li {
            margin-bottom: 15px;
          }
        }
        a, button {
          font-size: 16px;
        }
      }
    }
    .copyright {
      display: block;
      padding-top: 33px;
      margin-bottom: 70px;
      p {
        max-width: 100%;
      }
      p.current-year {
        margin-bottom: 32px;
      }
      p.disclaimer {
        &:before {
          content: '';
        }
      }
    }
    .logo, .social {
      margin-bottom: 0;
    }
    .social {
      display: flex;
      align-items: center;

      a {
        margin-bottom: 0;
        height: 46px;
        &.facebook {
          background-position: 63% center;
        }
        &.instagram {
          background-position: 100%;
        }
      }
    }
  }
`;
export const ModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border: none;
  button {
    background-image: url("/assets/images/CloseIcon.png");
    border: none;
    width: 31px;
    height: 31px;
    .sr-only{
      display: none;
    }
    span {
      display: none;
    }
  }
`;
export const ModalBody = styled(Modal.Body)`
  color: ${red};
  text-align: center;
  font-family: ${solaire};
  font-size: 25px;
  padding: 10px 0 50px;
`;
