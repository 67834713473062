/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const DebugFooterWrapper = styled.div`
  background: #faf9f6;
  .debug {
    font-size: 12px;
    overflow-wrap: anywhere;
    overflow-x: hidden;
  }
`;
